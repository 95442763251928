import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.bagasi.id/api';

const validationSchema = yup.object({
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Format email tidak valid'
    )
    .required('Email harus diisi'),
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, 'OTP harus 6 digit angka')
    .required('OTP harus diisi'),
  newPassword: yup
    .string()
    .min(6, 'Password minimal 6 karakter')
    .required('Password baru harus diisi'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Password tidak sama')
    .required('Konfirmasi password harus diisi'),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<'request' | 'reset' | 'success'>('request');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: step === 'request' 
      ? yup.object({ email: validationSchema.fields.email })
      : validationSchema,
    onSubmit: async (values) => {
      try {
        setError(null);
        setSuccess(null);
        setLoading(true);

        if (step === 'request') {
          // Request OTP
          const response = await axios.post(`${API_URL}/auth/request-reset-password`, { 
            email: values.email.toLowerCase() 
          });
          setSuccess(response.data.message);
          setStep('reset');
        } else if (step === 'reset') {
          // Hash password before sending
          const hashedPassword = btoa(values.newPassword);
          // Reset password
          const response = await axios.post(`${API_URL}/auth/reset-password`, {
            email: values.email.toLowerCase(),
            otp: values.otp,
            newPassword: hashedPassword
          });
          setSuccess('Password berhasil diubah');
          setStep('success');
        }
      } catch (err: any) {
        console.error('Reset password error:', err);
        setError(
          err.response?.data?.message ||
          'Terjadi kesalahan. Silakan coba lagi nanti.'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  if (step === 'success') {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          py: { xs: 2, sm: 4 },
          pt: { xs: '15vh', sm: '20vh' }
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            maxWidth: 400,
            width: '100%',
            mx: 2,
            textAlign: 'center'
          }}
        >
          <Typography variant="h5" gutterBottom>
            Password Berhasil Diubah
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Password Anda telah berhasil diubah. Silakan masuk dengan password baru Anda.
          </Typography>
          <Button
            component={RouterLink}
            to="/login"
            variant="contained"
            color="primary"
            fullWidth
          >
            Masuk
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        py: { xs: 2, sm: 4 },
        pt: { xs: '15vh', sm: '20vh' }
      }}
    >
      <Helmet>
        <title>Reset Password - Bagasi</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          mx: 2,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          {step === 'request' ? 'Reset Password' : 'Konfirmasi Reset Password'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            disabled={step === 'reset'}
          />

          {step === 'reset' && (
            <>
              <TextField
                fullWidth
                id="otp"
                name="otp"
                label="Kode OTP"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={formik.touched.otp && formik.errors.otp}
                margin="normal"
              />

              <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label="Password Baru"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                margin="normal"
              />

              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Konfirmasi Password"
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                margin="normal"
              />
            </>
          )}

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              step === 'request' ? 'Kirim OTP' : 'Reset Password'
            )}
          </Button>

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              <Link component={RouterLink} to="/login">
                Kembali ke halaman login
              </Link>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
