import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Alert,
  useTheme,
  Grid,
  Button
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { toast } from 'react-toastify';

interface Message {
  _id: string;
  content: string;
  sender: {
    _id: string;
    username: string;
  };
  timestamp: string;
  read: boolean;
  optimistic?: boolean;
}

interface ChatRoom {
  id: string;
  ad: {
    id: string;
    productUrl: string;
  };
  shopper: {
    id: string;
    username: string;
  };
  traveler: {
    id: string;
    username: string;
  };
  messages: Message[];
}

interface ChatRoomProps {
  adId: string;
}

const ChatRoom: React.FC<ChatRoomProps> = ({ adId }) => {
  const { user } = useAuth();
  const [chat, setChat] = useState<ChatRoom | null>(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await api.get(`/chat/ad/${adId}`);
        setChat(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat:', error);
        setError('Failed to load chat');
        setLoading(false);
      }
    };

    fetchChat();

    // Set up polling for new messages
    const pollInterval = setInterval(fetchChat, 5000);

    return () => clearInterval(pollInterval);
  }, [adId]);

  // Scroll to bottom when chat messages are loaded
  useEffect(() => {
    if (chat && Array.isArray(chat.messages) && chat.messages.length > 0 && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chat?.messages]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!message.trim()) return;

    const optimisticMessage: Message = {
      _id: Date.now().toString(),
      sender: { 
        _id: user?.id || '', 
        username: user?.username || '' 
      },
      content: message,
      timestamp: new Date().toISOString(),
      read: false,
      optimistic: true
    };

    // Add optimistic message
    setChat(prevChat => {
      if (!prevChat) return null;
      return {
        ...prevChat,
        messages: [...(Array.isArray(prevChat.messages) ? prevChat.messages : []), optimisticMessage]
      };
    });

    setMessage('');

    // Scroll to bottom after sending message
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }

    try {
      const response = await api.post(`/chat/ad/${adId}/messages`, {
        content: message,
      });

      // Replace optimistic message with real one
      setChat((prevChat) => {
        if (!prevChat) return null;
        return {
          ...prevChat,
          messages: prevChat.messages.map(msg => 
            msg._id === optimisticMessage._id ? response.data : msg
          ),
        };
      });

      setError(null);
    } catch (err: any) {
      console.error('Error sending message:', err);
      setError(err.response?.data?.message || 'Failed to send message');
      
      // Remove optimistic message on error
      setChat((prevChat) => {
        if (!prevChat) return null;
        return {
          ...prevChat,
          messages: prevChat.messages.filter(msg => msg._id !== optimisticMessage._id),
        };
      });
      
      toast.error('Failed to send message. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  if (!chat) {
    return (
      <Alert severity="info">
        No chat found for this ad
      </Alert>
    );
  }

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      gap: 1,
      mb: 1
    }}>
      <Paper
        ref={chatContainerRef}
        elevation={0}
        sx={{
          p: 1.5,
          height: '60vh',
          overflowY: 'auto',
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          mb: 1
        }}
      >
        {chat.messages.map((msg) => {
          const isOwnMessage = msg.sender._id === user?.id;

          return (
            <Box
              key={msg._id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
                mb: 1.5,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  bgcolor: isOwnMessage ? theme.palette.primary.main : 'white',
                  color: isOwnMessage ? 'white' : 'text.primary',
                  borderRadius: 2,
                  p: 1.5,
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                  position: 'relative',
                  maxWidth: '70%',
                  width: 'fit-content',
                }}
              >
                <Typography variant="body2">
                  {msg.content}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 0.5,
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: isOwnMessage ? 'rgba(255,255,255,0.8)' : 'text.secondary',
                      fontSize: '0.75rem',
                    }}
                  >
                    {msg.sender.username}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: isOwnMessage ? 'rgba(255,255,255,0.8)' : 'text.secondary',
                      fontSize: '0.75rem',
                    }}
                  >
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Paper>

      <Box component="form" onSubmit={handleSendMessage}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Kirim pesan..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!message.trim()}
              sx={{
                height: '40px',
                minWidth: '80px',
                borderRadius: 2,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
            >
              <SendIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChatRoom;
