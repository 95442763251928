import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import { CheckCircleOutline as CheckCircleIcon } from '@mui/icons-material';
import { ads } from '../services/api';
import { generateAdUrl } from '../utils/url';
import { Ad } from '../types';
import { useAuth } from '../contexts/AuthContext';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { adData, message } = location.state || {};
  const [error, setError] = useState<string | null>(null);
  const [createdAd, setCreatedAd] = useState<Ad | null>(null);

  useEffect(() => {
    const createAd = async () => {
      if (adData && user) {
        try {
          // Include user's WhatsApp number and preserve currency in the ad data
          const adWithUserData = {
            ...adData,
            whatsappNumber: user.whatsappNumber || '',
            currency: adData.currency  // Explicitly preserve currency
          };
          const newAd = await ads.create(adWithUserData);
          setCreatedAd(newAd);
        } catch (error) {
          console.error('Error creating ad:', error);
          setError('Gagal membuat iklan. Silakan coba lagi.');
        }
      }
    };

    createAd();
  }, [adData, user]);

  const handleViewAd = () => {
    if (createdAd) {
      navigate(generateAdUrl(createdAd));
    } else {
      navigate('/ads');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Paper sx={{ p: 4, maxWidth: 400, width: '100%', textAlign: 'center' }}>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <CheckCircleIcon
            color="success"
            sx={{ fontSize: 64, mb: 2 }}
          />
        )}
        <Typography variant="h5" gutterBottom>
          {error ? 'Pembayaran Gagal' : 'Pembayaran Berhasil!'}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {message || (error ? 'Silakan coba lagi.' : 'Iklan Anda akan segera ditampilkan di marketplace.')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewAd}
          sx={{ mt: 2 }}
        >
          Lihat Iklan Saya
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentSuccess;
