import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Chip,
  Divider,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link as MuiLink,
  Rating
} from '@mui/material';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { formatCurrency, formatUrlSlug } from '../utils/format';
import ChatRoom from '../components/ChatRoom';
import api from '../services/api';
import { toast } from 'react-toastify';
import { Commission, Currency, ShopperAd } from '../types';
import Footer from '../components/Footer';

const generateSEOTitle = (ad: ShopperAd | null) => {
  if (!ad) return 'Jastip Belanja - Bagasi';
  return `Jastip ${ad.productName} - Bagasi`;
};

const generateSEODescription = (ad: ShopperAd | null) => {
  if (!ad) return 'Jastip belanja produk luar negeri dengan Bagasi';
  const commission = ad.commission ? ad.commission.idr : 0;
  return `Jastip belanja ${ad.productName} dengan komisi ${formatCurrency(commission)}. Berat produk: ${ad.productWeight}kg.`;
};

const generateStructuredData = (ad: ShopperAd | null) => {
  if (!ad) return null;

  // Set price valid until 30 days from now
  const priceValidUntil = new Date();
  priceValidUntil.setDate(priceValidUntil.getDate() + 30);

  const structuredData: any = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": ad.productName || '',
    "description": generateSEODescription(ad),
    "offers": {
      "@type": "Offer",
      "price": ad.commission ? ad.commission.idr : 0,
      "priceCurrency": "IDR",
      "availability": ad.status === 'active' ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
      "seller": {
        "@type": "Person",
        "name": ad.user?.username || ad.customDisplayName || 'Unknown Seller'
      },
      "priceValidUntil": priceValidUntil.toISOString().split('T')[0],
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "0",
          "currency": "IDR"
        }
      }
    },
    "image": ad.cloudflareImageUrl || ad.productImage,
    "weight": {
      "@type": "QuantitativeValue",
      "value": ad.productWeight,
      "unitCode": "KGM"
    },
    "brand": {
      "@type": "Brand",
      "name": ad.merchantName || "Unknown"
    },
    "url": window.location.href,
    "sku": ad._id,
    "itemCondition": "https://schema.org/NewCondition",
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1"
      },
      "author": {
        "@type": "Person",
        "name": "Bagasi"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "reviewCount": "1",
      "bestRating": "5",
      "worstRating": "1"
    }
  };

  // Add shipping destination only if shippingAddress exists and has city
  if (ad.shippingAddress?.city) {
    structuredData.offers.shippingDetails.shippingDestination = {
      "@type": "DefinedRegion",
      "addressCountry": "ID",
      "addressRegion": ad.shippingAddress.city
    };
  }

  // Add delivery time
  structuredData.offers.shippingDetails.deliveryTime = {
    "@type": "ShippingDeliveryTime",
    "handlingTime": {
      "@type": "QuantitativeValue",
      "minValue": "1",
      "maxValue": "3",
      "unitCode": "DAY"
    },
    "transitTime": {
      "@type": "QuantitativeValue",
      "minValue": "2",
      "maxValue": "5",
      "unitCode": "DAY"
    }
  };

  // Add hasMerchantReturnPolicy
  structuredData.offers.hasMerchantReturnPolicy = {
    "@type": "MerchantReturnPolicy",
    "applicableCountry": "ID",
    "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
    "merchantReturnDays": "7",
    "returnMethod": "https://schema.org/ReturnByMail",
    "returnFees": "https://schema.org/FreeReturn"
  };

  return structuredData;
};

const ShopperAdDetails: React.FC = () => {
  const { id, productName } = useParams<{ id: string; productName: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ad, setAd] = useState<ShopperAd | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [processingAction, setProcessingAction] = useState(false);

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const response = await api.get(`/shopper-ads/${id}`);
        setAd(response.data);
      } catch (err) {
        console.error('Error fetching ad:', err);
        setError('Failed to load ad details');
      } finally {
        setLoading(false);
      }
    };

    fetchAd();
  }, [id]);

  const handleRequestHelp = async () => {
    if (!user) {
      navigate('/login', { state: { from: `/jastip-belanja/${formatUrlSlug(ad?.productName || 'product')}/${id}` } });
      return;
    }
    if (user.role !== 'traveler') {
      toast.error('Anda harus login sebagai traveler untuk membantu membeli');
      return;
    }
    try {
      setProcessingAction(true);
      const response = await api.post(`/shopper-ads/${id}/request`);
      if (!user.id || !user.username) {
        throw new Error('Invalid user data');
      }
      const updatedAd = {
        ...ad,
        ...response.data,
        status: 'in_discussion',
        selectedTraveler: {
          id: user.id,
          username: user.username
        }
      };
      setAd(updatedAd);
      toast.success('Request sent successfully!');
      // Only show chat after ad is updated
      setTimeout(() => setShowChat(true), 100);
    } catch (err) {
      console.error('Error requesting help:', err);
      toast.error('Failed to send request');
    } finally {
      setProcessingAction(false);
    }
  };

  const handleAcceptTraveler = async () => {
    try {
      setProcessingAction(true);
      await api.post(`/shopper-ads/${id}/accept-traveler`);
      setAd(prev => prev ? { ...prev, status: 'accepted' } : null);
      setShowAcceptDialog(false);
      toast.success('Traveler accepted successfully!');
    } catch (err) {
      console.error('Error accepting traveler:', err);
      toast.error('Failed to accept traveler');
    } finally {
      setProcessingAction(false);
    }
  };

  const handleCancel = async () => {
    try {
      setProcessingAction(true);
      const response = await api.patch(`/shopper-ads/${id}/cancel`);

      // Update the ad state based on the response
      setAd(prev => {
        if (!prev) return null;

        const isUserShopper = user?.id === prev.user.id;
        const isActiveStatus = prev.status === 'active';

        // If shopper cancels active ad, it becomes cancelled
        if (isUserShopper && isActiveStatus) {
          return { ...prev, status: 'cancelled' };
        }

        // For all other cases (traveler cancel or shopper reject), ad goes back to active
        return {
          ...prev,
          status: 'active',
          selectedTraveler: null
        };
      });

      setShowCancelDialog(false);
      toast.success(response.data.message);
    } catch (err) {
      console.error('Error cancelling order:', err);
      toast.error('Failed to cancel order');
    } finally {
      setProcessingAction(false);
    }
  };

  const handleComplete = async () => {
    try {
      setProcessingAction(true);
      await api.patch(`/shopper-ads/${id}/complete`);
      setAd(prev => prev ? { ...prev, status: 'completed' } : null);
      toast.success('Order marked as completed!');
    } catch (err) {
      console.error('Error completing order:', err);
      toast.error('Failed to complete order');
    } finally {
      setProcessingAction(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return theme.palette.success.main;
      case 'in_discussion':
        return theme.palette.warning.main;
      case 'accepted':
        return theme.palette.info.main;
      case 'shipped':
        return theme.palette.primary.main;
      case 'completed':
        return theme.palette.success.dark;
      default:
        return theme.palette.grey[500];
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'active':
        return 'Bisa Diambil';
      case 'in_discussion':
        return 'Sedang Diskusi';
      case 'accepted':
        return 'Dalam Proses';
      case 'shipped':
        return 'Dikirim Kurir Lokal';
      case 'completed':
        return 'Selesai';
      default:
        return 'Dibatalkan';
    }
  };

  const getCancelButtonText = () => {
    if (!ad) return '';

    const isUserShopper = user?.id === ad.user.id;

    if (isUserShopper) {
      if (ad.status === 'active') return 'Batalkan Permanen';
      if (ad.status === 'in_discussion') return 'Tolak Traveler';
      return '';
    }

    // For travelers
    if (['in_discussion', 'accepted', 'shipped'].includes(ad.status)) {
      return 'Batalkan Order';
    }

    return '';
  };

  const showCancelButton = () => {
    if (!ad?.user?.id || !user?.id) return false;

    const isUserShopper = user.id === ad.user.id;
    const isUserTraveler = ad.selectedTraveler?.id && user.id === ad.selectedTraveler.id;

    if (isUserShopper) {
      return ['active', 'in_discussion'].includes(ad.status);
    }

    if (isUserTraveler) {
      return ['in_discussion', 'accepted', 'shipped'].includes(ad.status);
    }

    return false;
  };

  const getCurrency = (currency: string | undefined): Currency => {
    if (!currency) return 'AUD';
    return currency as Currency;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !ad) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error || 'Ad not found'}</Alert>
      </Container>
    );
  }

  const isUserShopper = user?.id === ad.user?.id;
  const isUserTraveler = user?.id === ad?.selectedTraveler?.id;
  const canShowFullAddress = ad.status === 'accepted' && isUserTraveler;

  const sellerName = ad.customDisplayName || ad.user?.username || 'Unknown Seller';
  const sellerRating = ad.customRating || ad.user?.rating || 0;
  const sellerTotalReviews = ad.user?.totalReviews || 0;

  return (
    <>
      <Helmet>
        <title>{generateSEOTitle(ad)}</title>
        <meta name="description" content={generateSEODescription(ad)} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={generateSEOTitle(ad)} />
        <meta property="og:description" content={generateSEODescription(ad)} />
        <meta property="og:image" content={ad?.cloudflareImageUrl || ad?.productImage} />
        <meta property="og:url" content={window.location.href} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={generateSEOTitle(ad)} />
        <meta name="twitter:description" content={generateSEODescription(ad)} />
        <meta name="twitter:image" content={ad?.cloudflareImageUrl || ad?.productImage} />

        {/* Additional SEO */}
        <meta name="keywords" content={`jastip, ${ad?.productName || ''}, titip beli, jasa titip, bagasi`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />

        {/* Structured Data */}
        {ad && (
          <script type="application/ld+json">
            {JSON.stringify(generateStructuredData(ad))}
          </script>
        )}
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 2, color: theme.palette.text.secondary }}>
          Detail Produk Jastip
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{ flex: 1 }}>
                  <Chip
                    label={getStatusText(ad.status)}
                    sx={{ bgcolor: getStatusColor(ad.status), color: 'white', mb: 1 }}
                  />
                  <Typography
                    component={RouterLink}
                    to={ad.productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="h6"
                    sx={{
                      display: 'block',
                      mt: 1,
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {ad.productName}
                  </Typography>
                </Box>
                {ad.selectedTraveler && (
                  <Typography variant="subtitle2" color="text.secondary">
                    Jastiper: {ad.selectedTraveler.username}
                  </Typography>
                )}
              </Box>

              <Box sx={{ mb: 3 }}>
                <img
                  src={ad.cloudflareImageUrl || ad.productImage}
                  alt={ad.productName}
                  style={{
                    width: '100%',
                    maxHeight: '400px',
                    objectFit: 'contain',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px'
                  }}
                  onError={(e) => {
                    const img = e.target as HTMLImageElement;
                    if (!img.src.includes('placeholder')) {
                      img.src = 'https://placehold.co/400x400?text=Image+Not+Available';
                    }
                  }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '45%', pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Nama Toko
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <MuiLink
                          href={ad.productUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {ad.merchantName || (() => {
                            try {
                              return new URL(ad.productUrl).hostname;
                            } catch (e) {
                              return 'Unknown Merchant';
                            }
                          })()}
                        </MuiLink>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Harga per Unit
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <span style={{ fontSize: '0.875rem' }}>
                            {formatCurrency(ad.productPrice, getCurrency(ad.productCurrency || ad.commission?.currency))}
                          </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Berat per Unit
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <span style={{ fontSize: '0.875rem' }}>{(ad.productWeight || 0).toFixed(2)}</span>
                          <span style={{ fontSize: '0.75rem', color: 'text.secondary' }}>KG</span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Kuantitas
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <span style={{ fontSize: '0.875rem' }}>{ad.quantity || 1}</span>
                          <span style={{ fontSize: '0.75rem', color: 'text.secondary' }}>pcs</span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Biaya Total
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 1 }}>
                          <span style={{ fontSize: '0.875rem', color: 'text.secondary' }}>
                            IDR {(ad.productPriceIDR * (ad.quantity || 1)).toLocaleString('id-ID')}
                          </span>
                          <span style={{ fontSize: '0.75rem', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.38)' }}>
                            {formatCurrency(ad.productPrice * (ad.quantity || 1), getCurrency(ad.productCurrency || ad.commission?.currency))}
                          </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Berat Total
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <span style={{ fontSize: '0.875rem' }}>
                            {(ad.totalWeight || (ad.productWeight * (ad.quantity || 1))).toFixed(2)}
                          </span>
                          <span style={{ fontSize: '0.75rem', color: 'text.secondary' }}>KG</span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Dipesan oleh
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(224, 224, 224, 0.4)' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="body2">
                            {ad.user?.username || '-'}
                          </Typography>
                          {(ad.user?.rating || 0) > 0 && (
                            <Box
                              sx={{
                                color: 'white',
                                bgcolor: (ad.user?.rating || 0) >= 4 ? '#28a745' : 
                                       (ad.user?.rating || 0) >= 3 ? '#ffc107' : '#dc3545',
                                fontSize: isMobile ? '0.6rem' : '0.75rem',
                                padding: isMobile ? '0px 2px' : '1px 4px',
                                borderRadius: '3px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: '20px',
                                height: isMobile ? '16px' : '18px',
                                lineHeight: 1,
                                mt: '1px' // Add a tiny top margin to align with username
                              }}
                            >
                              {(ad.user?.rating || 0).toFixed(1)}
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box sx={{ mt: 3, bgcolor: 'primary.main', p: 2, borderRadius: 1 }}>
                  <Typography variant="subtitle1" color="white" gutterBottom>
                    Komisi Traveler
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                    <Typography color="white" sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
                      {formatCurrency(ad.commission?.idr || 0, 'IDR')}
                    </Typography>
                    <Typography color="white" sx={{ opacity: 0.8 }}>
                      ({formatCurrency(ad.commission?.native || 0, ad.commission?.currency)})
                    </Typography>
                  </Box>
                  <Typography
                    color="white"
                    sx={{
                      mt: 1,
                      fontSize: '0.75rem',
                      fontStyle: 'italic',
                      opacity: 0.7,
                      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                      pt: 1
                    }}
                  >
                    Total dana yang akan dikirimkan secara otomatis senilai{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      IDR {((ad.productPriceIDR * (ad.quantity || 1)) + (ad.commission?.idr || 0)).toLocaleString('id-ID')}
                    </span>
                    {' '}setelah titipan telah diterima oleh pembeli
                  </Typography>
                </Box>

                <Box sx={{ mt: 3, display: 'flex', gap: 4 }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Kirim Ke
                    </Typography>
                    <Typography variant="body2">
                      {canShowFullAddress && ad.shippingAddress?.fullAddress ? ad.shippingAddress.fullAddress : ad.shippingAddress ? `${ad.shippingAddress.city}, ${ad.shippingAddress.country}` : 'Address not available'}
                    </Typography>
                  </Box>

                  <Box sx={{ minWidth: '100px' }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Kurir Lokal
                    </Typography>
                    <Typography variant="body2">
                      {ad.localCourier}
                    </Typography>
                  </Box>
                </Box>

                {ad.notes && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Catatan
                    </Typography>
                    <Typography variant="body2">
                      {ad.notes}
                    </Typography>
                  </Box>
                )}

                {ad.trackingNumber && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Nomor Resi
                    </Typography>
                    <Typography variant="body2">
                      {ad.trackingNumber}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ mt: 3, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-end' }}>
                {isUserShopper ? (
                  <>
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        borderRadius: '16px',
                        px: 2,
                        py: 0.75,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          fontSize: '0.75rem',
                          fontStyle: 'italic'
                        }}
                      >
                        Kamu pemilik order jastip ini
                      </Typography>
                    </Box>
                    {ad.status !== 'cancelled' && !['accepted', 'shipped', 'completed'].includes(ad.status) && (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/shopper-ads/${ad._id}/edit`)}
                          disabled={processingAction}
                          sx={{
                            fontWeight: 500,
                            px: 3,
                            py: 1,
                            position: 'relative',
                            minWidth: '100px',
                            minHeight: '36px'
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => setShowCancelDialog(true)}
                          disabled={processingAction}
                          sx={{
                            fontWeight: 500,
                            px: 3,
                            py: 1,
                            color: 'white',
                            position: 'relative',
                            minWidth: '100px',
                            minHeight: '36px'
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {ad.status === 'active' && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRequestHelp}
                        disabled={processingAction}
                        sx={{
                          fontWeight: 500,
                          px: 3,
                          py: 1,
                          position: 'relative',
                          minWidth: '100px',
                          minHeight: '36px'
                        }}
                      >
                        {processingAction ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        ) : (
                          'Bantu Beli'
                        )}
                      </Button>
                    )}

                    {ad.status === 'in_discussion' && isUserShopper && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAcceptDialog(true)}
                        disabled={processingAction}
                        sx={{
                          fontWeight: 500,
                          px: 3,
                          py: 1,
                          position: 'relative',
                          minWidth: '100px',
                          minHeight: '36px'
                        }}
                      >
                        {processingAction ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        ) : (
                          'Terima Jastiper'
                        )}
                      </Button>
                    )}

                    {ad.status === 'shipped' && isUserShopper && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleComplete}
                        disabled={processingAction}
                        sx={{
                          fontWeight: 500,
                          px: 3,
                          py: 1,
                          position: 'relative',
                          minWidth: '100px',
                          minHeight: '36px'
                        }}
                      >
                        {processingAction ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        ) : (
                          'Saya Sudah Terima Barang'
                        )}
                      </Button>
                    )}

                    {showCancelButton() && !isUserShopper && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setShowCancelDialog(true)}
                        disabled={processingAction}
                        sx={{
                          fontWeight: 500,
                          px: 3,
                          py: 1,
                          color: 'white',
                          position: 'relative',
                          minWidth: '100px',
                          minHeight: '36px'
                        }}
                      >
                        {processingAction ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                              color: 'white'
                            }}
                          />
                        ) : (
                          getCancelButtonText()
                        )}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>

          {user && (isUserShopper ||
            (isUserTraveler && ad.selectedTraveler?.id === user?.id && ad.status !== 'cancelled')) && (
              <Grid item xs={12} md={4}>
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    Chat
                  </Typography>
                  <ChatRoom adId={ad._id} />
                </Box>
              </Grid>
            )}
        </Grid>
      </Container>

      {/* Cancel Dialog */}
      <Dialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
      >
        <DialogTitle>
          {user?.id === ad?.user?.id && ad?.status === 'active'
            ? 'Batalkan Permanen?'
            : 'Batalkan Order?'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {user?.id === ad?.user?.id && ad?.status === 'active' ? (
              <>
                <p>Kamu yakin ingin membatalkan iklan Jastip ini secara permanen?</p>
                <p>
                  Kamu akan mendapatkan pengembalian dana sebesar{' '}
                  <strong>
                    Rp {(ad.productPriceIDR + (ad.commission?.idr || 0)).toLocaleString()}
                  </strong>{' '}
                  (Harga barang + Komisi untuk traveller).
                </p>
                <p style={{ color: '#666', fontSize: '0.9em', marginTop: '8px' }}>
                  *Refund akan diproses paling lama 14 hari kerja
                </p>
              </>
            ) : user?.id === ad?.user?.id
              ? 'Kamu yakin ingin menolak traveler ini? Iklan akan kembali aktif.'
              : 'Kamu yakin ingin membatalkan order ini? Banyak pembatalan dapat mengakibatkan akun dinonaktifkan.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCancelDialog(false)} disabled={processingAction}>
            Tidak
          </Button>
          <Button
            onClick={handleCancel}
            color="error"
            disabled={processingAction}
            sx={{ position: 'relative' }}
          >
            {processingAction ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            ) : (
              'Ya'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Accept Dialog */}
      <Dialog open={showAcceptDialog} onClose={() => setShowAcceptDialog(false)}>
        <DialogTitle>Konfirmasi Penerimaan</DialogTitle>
        <DialogContent>
          Apakah Anda yakin ingin menerima jastiper ini?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAcceptDialog(false)}>Tidak</Button>
          <Button onClick={handleAcceptTraveler} color="primary" disabled={processingAction}>
            Ya, Terima
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
};

export default ShopperAdDetails;
