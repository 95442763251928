import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Chip,
  Button,
} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import api from '../services/api';
import { ShopperAd } from '../types';

interface Props {
  shopperId: string;
}

const getStatusLabel = (status: string): string => {
  const statusMap: { [key: string]: string } = {
    draft: 'Draft',
    active: 'Aktif',
    in_discussion: 'Sedang Diskusi',
    accepted: 'Diterima',
    shipped: 'Dikirim',
    completed: 'Selesai',
    cancelled: 'Dibatalkan'
  };
  return statusMap[status] || status;
};

const getStatusColor = (status: string): 'default' | 'success' | 'error' | 'info' | 'warning' => {
  switch (status) {
    case 'draft':
      return 'default';
    case 'active':
      return 'success';
    case 'in_discussion':
      return 'info';
    case 'accepted':
      return 'success';
    case 'shipped':
      return 'warning';
    case 'completed':
      return 'success';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const formatPrice = (price: number, currency: string): string => {
  if (currency === 'IDR') {
    return `Rp ${price.toLocaleString()}`;
  } else {
    return `$ ${price.toLocaleString()}`;
  }
};

const ShopperAds: React.FC<Props> = ({ shopperId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [ads, setAds] = useState<ShopperAd[]>([]);
  const navigate = useNavigate();

  const columns: any[] = [
    {
      field: 'productName',
      headerName: 'Nama Produk',
      flex: 2,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar
            src={params.row.cloudflareImageUrl || params.row.productImage}
            alt={params.row.productName}
            variant="rounded"
            sx={{ width: 40, height: 40 }}
          />
          <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
            {params.row.productName.length > 30 
              ? `${params.row.productName.substring(0, 30)}...` 
              : params.row.productName}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      flex: 1,
      valueGetter: (params: any) => params.row.quantity || 1,
    },
    {
      field: 'weight',
      headerName: 'Berat',
      flex: 1,
      valueGetter: (params: any) => {
        const weight = params.row.totalWeight || (params.row.productWeight * (params.row.quantity || 1));
        return `${weight.toFixed(2)} kg`;
      },
    },
    {
      field: 'totalBiaya',
      headerName: 'Biaya Total',
      flex: 1,
      valueGetter: (params: any) => {
        const total = ((params.row.productPriceIDR * (params.row.quantity || 1)) + (params.row.commission?.idr || 0));
        return formatPrice(total, 'IDR');
      },
    },
    {
      field: 'selectedTraveler',
      headerName: 'Traveler',
      flex: 1,
      renderCell: (params: any) => {
        const traveler = params.row.selectedTraveler;
        if (!traveler) return '-';
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar
              src={traveler.avatar}
              alt={traveler.username}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="body2">{traveler.username}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: any) => (
        <Chip
          label={getStatusLabel(params.row.status)}
          color={getStatusColor(params.row.status)}
          size="small"
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Detail',
      flex: 0.5,
      sortable: false,
      renderCell: (params: any) => (
        <IconButton
          size="small"
          onClick={() => navigate(`/shopper-ads/${params.row._id}`)}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  const handleRowClick = (adId: string) => {
    navigate(`/shopper-ads/${adId}`);
  };

  useEffect(() => {
    const fetchAds = async () => {
      if (!shopperId) {
        setError('No shopper ID provided');
        setLoading(false);
        return;
      }

      try {
        const response = await api.get(`/shopper-ads/shopper/${shopperId}`);
        setAds(response.data);
        setError(null);
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to load ads');
      } finally {
        setLoading(false);
      }
    };

    fetchAds();
  }, [shopperId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (ads.length === 0) {
    return <Typography>No ads found.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field} style={{ padding: '12px 8px' }}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ads.map((ad) => (
            <TableRow
              key={ad._id}
              hover
              onClick={() => handleRowClick(ad._id)}
              style={{ cursor: 'pointer' }}
            >
              {columns.map((column) => (
                <TableCell key={column.field} style={{ padding: '12px 8px' }}>
                  {column.renderCell ? column.renderCell({ row: ad }) : column.valueGetter({ row: ad })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShopperAds;
