import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Alert,
  Typography,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { adminApi } from '../../services/api';

interface User {
  _id: string;
  username: string;
  email: string;
  whatsappNumber: string;
  isActive: boolean;
  isVerified: boolean;
  rating: number;
  totalReviews: number;
  createdAt: string;
  role: 'traveler' | 'shopper';
  fullname?: string;
  membership?: {
    type: 'none' | 'shopper';
    validUntil: string | null;
  };
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [editFullname, setEditFullname] = useState('');
  const [editRating, setEditRating] = useState<number>(0);
  const [editReviews, setEditReviews] = useState<number>(0);
  const [editingWhatsapp, setEditingWhatsapp] = useState(false);
  const [editingFullname, setEditingFullname] = useState(false);
  const [editingRating, setEditingRating] = useState(false);
  const [editingReviews, setEditingReviews] = useState(false);
  const [editingMembership, setEditingMembership] = useState(false);
  const [membershipType, setMembershipType] = useState<'none' | 'shopper'>('none');
  const [membershipValidUntil, setMembershipValidUntil] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await adminApi.getUsers();
      setUsers(response);
    } catch (err) {
      setError('Failed to fetch users');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleActiveToggle = async (userId: string) => {
    try {
      const response = await adminApi.toggleUserActive(userId);
      setUsers(users.map(user =>
        user._id === userId ? { ...user, isActive: response.isActive } : user
      ));
    } catch (err) {
      setError('Failed to update user status');
    }
  };

  const handleVerificationToggle = async (userId: string) => {
    try {
      const response = await adminApi.toggleUserVerification(userId);
      setUsers(users.map(user =>
        user._id === userId ? { ...user, isVerified: response.isVerified } : user
      ));
    } catch (err) {
      setError('Failed to update user verification');
    }
  };

  const handleWhatsappEdit = (user: User) => {
    setEditingUser(user);
    setWhatsappNumber(user.whatsappNumber || '');
    setEditingWhatsapp(true);
    setEditingFullname(false);
    setEditingRating(false);
    setEditingReviews(false);
    setEditingMembership(false);
    setError(null);
  };

  const handleEditFullname = (user: User) => {
    setEditingUser(user);
    setEditFullname(user.fullname || '');
    setEditingFullname(true);
    setEditingWhatsapp(false);
    setEditingRating(false);
    setEditingReviews(false);
    setEditingMembership(false);
    setError(null);
  };

  const handleRatingEdit = (user: User) => {
    setEditingUser(user);
    setEditRating(user.rating || 0);
    setEditingRating(true);
    setEditingWhatsapp(false);
    setEditingFullname(false);
    setEditingReviews(false);
    setEditingMembership(false);
    setError(null);
  };

  const handleReviewsEdit = (user: User) => {
    setEditingUser(user);
    setEditReviews(user.totalReviews || 0);
    setEditingReviews(true);
    setEditingWhatsapp(false);
    setEditingFullname(false);
    setEditingRating(false);
    setEditingMembership(false);
    setError(null);
  };

  const handleMembershipEdit = (user: User) => {
    setEditingUser(user);
    setMembershipType(user.membership?.type || 'none');
    setMembershipValidUntil(user.membership?.validUntil || '');
    setEditingMembership(true);
    setEditingWhatsapp(false);
    setEditingFullname(false);
    setEditingRating(false);
    setEditingReviews(false);
    setError(null);
  };

  const handleWhatsappSave = async () => {
    if (!editingUser) return;
    setIsSaving(true);
    setError(null);

    try {
      await adminApi.updateUserWhatsapp(editingUser._id, { whatsappNumber });
      await fetchUsers(); // Refresh the list to get updated data
      setEditingUser(null);
      setEditingWhatsapp(false);
    } catch (err) {
      setError('Failed to update WhatsApp number');
    } finally {
      setIsSaving(false);
    }
  };

  const handleFullnameSave = async () => {
    if (!editingUser) return;
    setIsSaving(true);
    setError(null);

    try {
      await adminApi.updateUserFullname(editingUser._id, { fullname: editFullname });
      await fetchUsers(); // Refresh the list to get updated data
      setEditingUser(null);
      setEditingFullname(false);
    } catch (err) {
      setError('Failed to update fullname');
    } finally {
      setIsSaving(false);
    }
  };

  const handleRatingSave = async () => {
    if (!editingUser) return;
    setIsSaving(true);
    setError(null);

    try {
      await adminApi.updateUserRating(editingUser._id, { rating: editRating });
      await fetchUsers();
      setEditingUser(null);
      setEditingRating(false);
    } catch (err) {
      setError('Failed to update rating');
    } finally {
      setIsSaving(false);
    }
  };

  const handleReviewsSave = async () => {
    if (!editingUser) return;
    setIsSaving(true);
    setError(null);

    try {
      await adminApi.updateUserReviews(editingUser._id, { totalReviews: editReviews });
      await fetchUsers();
      setEditingUser(null);
      setEditingReviews(false);
    } catch (err) {
      setError('Failed to update total reviews');
    } finally {
      setIsSaving(false);
    }
  };

  const handleMembershipSave = async () => {
    if (!editingUser) return;

    try {
      setIsSaving(true);
      const response = await adminApi.updateUserMembership(editingUser._id, {
        type: membershipType,
        validUntil: membershipValidUntil
      });

      setUsers(users.map(user =>
        user._id === editingUser._id ? { ...user, membership: response.membership } : user
      ));
      setEditingMembership(false);
    } catch (err) {
      setError('Failed to update membership');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDialogClose = () => {
    setEditingUser(null);
    setEditingWhatsapp(false);
    setEditingFullname(false);
    setEditingRating(false);
    setEditingReviews(false);
    setEditingMembership(false);
    setError(null);
    setIsSaving(false);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>User Management</Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>WhatsApp Number</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Reviews</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Membership</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      {user.username}
                      {user.fullname && (
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            fontStyle: 'italic', 
                            color: 'text.secondary',
                            mt: 0.5 
                          }}
                        >
                          {user.fullname}
                        </Typography>
                      )}
                    </Box>
                    <IconButton 
                      onClick={() => handleEditFullname(user)}
                      title="Edit Full Name"
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>{user.whatsappNumber}</span>
                    <IconButton 
                      onClick={() => handleWhatsappEdit(user)}
                      title="Edit WhatsApp"
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>
                  {user.role ? (user.role === 'traveler' ? 'Traveler' : 'Shopper') : 'Not Set'}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>{user.rating}</span>
                    <IconButton 
                      onClick={() => handleRatingEdit(user)}
                      title="Edit Rating"
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>{user.totalReviews || 0}</span>
                    <IconButton 
                      onClick={() => handleReviewsEdit(user)}
                      title="Edit Reviews"
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.isActive}
                    onChange={() => handleActiveToggle(user._id)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.isVerified || false}
                    onChange={() => handleVerificationToggle(user._id)}
                    color="success"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleMembershipEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  {user.membership?.type === 'shopper' ? (
                    <Typography variant="body2" color="primary">
                      Shopper (Until: {new Date(user.membership.validUntil || '').toLocaleDateString()})
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No Membership
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* WhatsApp Edit Dialog */}
      <Dialog open={Boolean(editingUser && editingWhatsapp)} onClose={handleDialogClose}>
        <DialogTitle>Edit WhatsApp Number</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="WhatsApp Number"
            type="text"
            fullWidth
            value={whatsappNumber}
            onChange={(e) => setWhatsappNumber(e.target.value)}
            disabled={isSaving}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isSaving}>Cancel</Button>
          <Button 
            onClick={handleWhatsappSave} 
            variant="contained" 
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fullname Edit Dialog */}
      <Dialog open={Boolean(editingUser && editingFullname)} onClose={handleDialogClose}>
        <DialogTitle>Edit Full Name</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={editFullname}
            onChange={(e) => setEditFullname(e.target.value)}
            disabled={isSaving}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isSaving}>Cancel</Button>
          <Button 
            onClick={handleFullnameSave} 
            variant="contained"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rating Edit Dialog */}
      <Dialog open={Boolean(editingUser && editingRating)} onClose={handleDialogClose}>
        <DialogTitle>Edit Rating</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Rating (0-5)"
            type="number"
            fullWidth
            value={editRating}
            onChange={(e) => setEditRating(Math.min(5, Math.max(0, Number(e.target.value))))}
            disabled={isSaving}
            inputProps={{ min: 0, max: 5, step: 0.1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isSaving}>Cancel</Button>
          <Button 
            onClick={handleRatingSave} 
            variant="contained"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reviews Edit Dialog */}
      <Dialog open={Boolean(editingUser && editingReviews)} onClose={handleDialogClose}>
        <DialogTitle>Edit Total Reviews</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Total Reviews"
            type="number"
            fullWidth
            value={editReviews}
            onChange={(e) => setEditReviews(Math.max(0, Number(e.target.value)))}
            disabled={isSaving}
            inputProps={{ min: 0, step: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isSaving}>Cancel</Button>
          <Button 
            onClick={handleReviewsSave} 
            variant="contained"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Membership Edit Dialog */}
      <Dialog open={Boolean(editingMembership)} onClose={() => setEditingMembership(false)}>
        <DialogTitle>Edit Membership</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Membership Type"
              value={membershipType}
              onChange={(e) => setMembershipType(e.target.value as 'none' | 'shopper')}
              sx={{ mb: 2 }}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="shopper">Shopper</MenuItem>
            </TextField>
            <TextField
              type="date"
              fullWidth
              label="Valid Until"
              value={membershipValidUntil}
              onChange={(e) => setMembershipValidUntil(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingMembership(false)}>Cancel</Button>
          <Button onClick={handleMembershipSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
