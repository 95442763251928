import { Currency } from '../types';

export const formatPrice = (price: number | undefined, currency: Currency | 'IDR' = 'IDR'): string => {
  if (price === undefined) return '-';
  
  const localeMap: { [key: string]: string } = {
    IDR: 'id-ID',
    AUD: 'en-AU',
    USD: 'en-US',
    SGD: 'en-SG',
    KRW: 'ko-KR',
    JPY: 'ja-JP',
    EUR: 'de-DE'
  };

  const locale = localeMap[currency] || 'en-US';
  
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: currency === 'JPY' ? 0 : 2,
    maximumFractionDigits: currency === 'JPY' ? 0 : 2
  }).format(price);
};

export const formatUrlSlug = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const formatCurrency = formatPrice;
